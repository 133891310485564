<template>
  <MemberSettingContainer>
    <!-- Seetings form -->
    <div v-if="isLoading" class="my-3">
      <b-spinner class="text-primary d-block m-auto" />
    </div>

    <LocalSettings @isLoading="(loading) => isLoading = loading" />

  </MemberSettingContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';

export default {
  name: 'LocalMemberSettings',
  components: {
    ValidationProvider,
    ValidationObserver,
    MemberSettingContainer,
    LocalSettings: () => import('@/views/myself/components/LocalSettings.vue' /* webpackChunkName: "LocalSettings" */),
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    ...mapGetters(['loggedMember', 'currentCollective']),
    
  },
  async created() {
    this.isStaff = this.currentCollective.isStaff;
  },
  methods: {
    //
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
</style>
